const data = [
    {
      responsive_id: '',
      numero: 100,
      descricao: 'Renegociação parcela Nº 23',
      status: 0,
      data_cadastro: '12/03/2022',
      data_fim: "13/03/2022"
    },
    {
      responsive_id: '',
      numero: 200,
      descricao: 'Renegociação parcela Nº 34',
      status: 2,
      data_cadastro: '31/04/2022',
      data_fim: "03/05/2022"
    },
    {
      responsive_id: '',
      numero: 300,
      descricao: 'Renegociação parcela Nº 89',
      status: 1,
      data_cadastro: '31/04/2022',
      data_fim: ""
    },
  ]
  
  export default data
  