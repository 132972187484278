var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('span',{staticClass:"mt-3 d-flex align-items-center"},[_vm._v(" Canais de comunicação")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6","sm":"6"}}),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-divider'),_c('v-row',{staticClass:"my-4 mx-2",staticStyle:{"min-height":"auto","width":"100%"},attrs:{"justify":"center"}},[_c('v-data-table',{staticClass:"table-rounded custom-data-table",staticStyle:{"width":"100%"},attrs:{"headers":this.headers,"items":this.empreendimentos,"item-key":"id","hide-default-footer":"","disable-sort":"","fixed-header":"","no-data-text":"Nenhum empreendimento encontrado","no-results-text":"Nenhum empreendimento encontrado"},scopedSlots:_vm._u([{key:"item.obra",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"blue darken-3","outlined":""}},[_vm._v(" "+_vm._s(item.obra)+" ")])]}},{key:"item.empreendimento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"blue darken-3","outlined":""}},[_vm._v(" "+_vm._s(item.empreendimento)+" ")])]}},{key:"item.whatsapp",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"teal darken-","outlined":""}},[_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"#4CAF50"},attrs:{"href":("https://api.whatsapp.com/send?phone=55" + (item.whatsapp.replace(/\D/g, ''))),"target":"_blank"}},[_vm._v(_vm._s(item.whatsapp))])])]}},{key:"item.site",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"blue darken-1","outlined":""}},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.site,"target":"_blank"}},[_vm._v(_vm._s(item.site))])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"blue darken-1","outlined":""}},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])])]}},{key:"item.atendimento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"blue darken-3","outlined":""}},[_vm._v(" "+_vm._s(item.atendimento)+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }