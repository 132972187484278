<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="6" sm="6">
          <span class="mt-3 d-flex align-items-center">
            &nbsp; Canais de comunicação</span>
        </v-col>
        <v-col cols="6" sm="6" class="d-flex justify-end">
          <!--
          <v-btn
          color="success"
          class="me-3"
          >
          <v-icon icon>
              {{icons.mdiPlusOutline}}
          </v-icon>
          Novo atendimento
          </v-btn>
          -->
        </v-col>
          <v-spacer></v-spacer>
          <!-- <span class="text-xs text--disabled cursor-pointer">Ver todas</span> -->
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <v-row style="min-height: auto; width: 100%;" justify="center" class="my-4 mx-2">
        <v-data-table
          :headers="this.headers"
          :items="this.empreendimentos"
          item-key="id"
          class="table-rounded custom-data-table"
          hide-default-footer
          disable-sort
          fixed-header
          no-data-text="Nenhum empreendimento encontrado"
          no-results-text="Nenhum empreendimento encontrado"
          style="width: 100%;"
        >
        <!-- Empresa -->
        <template #[`item.obra`]="{item}">
          <v-chip
              small
              color="blue darken-3"
              outlined
              class="font-weight-medium"
          >
              {{ item.obra }}
          </v-chip>
        </template>

          <!-- Obra -->
          <template #[`item.empreendimento`]="{item}">
            <v-chip
                small
                color="blue darken-3"
                outlined
                class="font-weight-medium"
            >
                {{ item.empreendimento }}
            </v-chip>
            </template>

            <!-- Tel. whatsapp -->
            <template #[`item.whatsapp`]="{item}">
            <v-chip
                small
                color="teal darken-"
                outlined
                class="font-weight-medium"
            >
                <a :href="`https://api.whatsapp.com/send?phone=55${item.whatsapp.replace(/\D/g, '')}`" style="color: #4CAF50;" target="_blank" class="text-decoration-none">{{ item.whatsapp }}</a>
            </v-chip>
            </template>

            <!-- Site -->
          <template #[`item.site`]="{item}">
            <v-chip
                small
                color="blue darken-1"
                outlined
                class="font-weight-medium"
            >
                <a :href="item.site" target="_blank" class="text-decoration-none">{{ item.site }}</a>
            </v-chip>
            </template>

             <!-- Email -->
          <template #[`item.email`]="{item}">
            <v-chip
                small
                color="blue darken-1"
                outlined
                class="font-weight-medium"
            >
                <a :href="`mailto:${item.email}`" class="text-decoration-none">{{ item.email }}</a>
            </v-chip>
            </template>

              <!-- Horario atendimento -->
          <template #[`item.atendimento`]="{item}">
            <v-chip
                small
                color="blue darken-3"
                outlined
                class="font-weight-medium"
            >
                {{ item.atendimento }}
            </v-chip>
            </template>
        </v-data-table>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiTicketConfirmation, mdiWhatsapp, mdiAt, mdiPhoneClassic, mdiWeb, mdiOfficeBuildingMarkerOutline } from '@mdi/js'
import data from './datatable-data'
import Strings from '@/common/strings'
import UsuarioStore from './../../store/Usuario'
import Cookie from 'js-cookie'

export default {
  mounted: function(){
    this.$loading(true)

    UsuarioStore.obterContatoObra(Strings.imobiliariaId).then(result => {
      let contatos = result.data

      contatos.forEach(obra => {
        var fUnidades = {
          obra: obra.Obra,
          empreendimento: obra.Empreendimento,
          empresa: obra.Empresa,
          whatsapp: `${obra.Whatsapp}`,
          site: process.env.VUE_APP_ATENDIMENTO_SITE,
          email: `${obra.Email}`,
          atendimento: obra.HorarioAtendimento
        }

        this.empreendimentos.push(fUnidades)
      });

      this.$loading(false)
    }).catch(error => {
        if(error.response.status === 401){
            Cookie.remove(Strings.cookie_token)
            Cookie.remove(Strings.cookie_username)
            this.$router.push('/pages/login')
        }
        console.log(error)
        this.toastText = error.response.data
        this.snackbar = true
        this.$loading(false)
    })
  },
  data(){
    return {
      whatsapp: "https://api.whatsapp.com/send?phone=55" + Strings.whatsapp,
      email: "mailto:" + Strings.email,
      telefone: "tel:" + Strings.telefone,
      site: Strings.site,
      exibirHorarioFuncionamento: Strings.exibir_horario_funcionamento,
      empreendimentos: []
    }
  },
  setup() {
    const obr = []
    const unidades = []
    const statusColor = {
      0: 'error',
      1: 'info',
      2: 'success',
    }

    return {
      obr,
      unidades,
      headers: [
        { text: 'Empresa', value: 'obra' },
        { text: 'Empreendimento', value: 'empreendimento' },
        { text: 'Whatsapp', value: 'whatsapp' },
        { text: 'Site', value: 'site' },
        { text: 'Email', value: 'email' },
        { text: 'Horário atendimento', value: 'atendimento' },
      ],
      usreList: data,
      status: {
        0: 'Aberto',
        1: 'Em andamento',
        2: 'Finalizado',
      },
      statusColor,

      // icons
      icons: {
        mdiTicketConfirmation,
        mdiWhatsapp,
        mdiAt,
        mdiPhoneClassic,
        mdiWeb,
        mdiOfficeBuildingMarkerOutline
      },
    }
  },
}
</script>
<style scoped>
.btn-bol{
  border-radius: 40px !important;
  width: 5rem !important;
  background: rgb(0, 136, 255);
  height: 1.6rem !important;
}
</style>
