<template>
    <v-row>
        <v-col
        cols="12"
        md="12">        
        <table-atendimentos></table-atendimentos>
        </v-col>
    </v-row>
</template>

<script>

import TableAtendimentos from './TableAtendimento.vue'

export default {
    components: {
        TableAtendimentos
    }
}
</script>